<style scoped>
  .header-box {
    background: #4C79FF;
    padding: 25px 19px 290px;
    height: 100%;
    position: relative;
  }

  .backfff {
    background-color: #FFFFFF;
    padding: 37px 68px 0 69px;
    border-radius: 15px 15px 0 0;
  }

  .bookimg>img {
    width: 100%;
    height: 100%;

border-radius: 5px;
  }

  .userimg {
    text-align: center;
    height: 25px;
  }

  .userimg>img {
    width: 52px;
    height: 52px;
    position: relative;
    top: -26px;

border-radius: 50%;
  }

  .text {
    margin-top: 11px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    padding-bottom: 11px;
  }

  .middleimg {
    height: 28px;
  }

  .middleimg>img {
    width: 100%;
    height: 100%;
  }

  .backfffbottom {
    background-color: #FFFFFF;
    border-radius: 0 0 15px 15px;
    padding: 12px 32px 20px 33px;
  }

  .addbtn {
    padding: 12px 0;
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    border-radius: 5px;
    background: #547CFF;
    padding-top: 10px;
  }
  /* .share_box{
    display: block!important;
  } */
</style>
<template>
  <div class="share_box">
    <header-title :titleCon="titleCon" titleBtnStr="" @titleLeftBtn="titleLeftListener">
    </header-title>
    <div class="header-box">
      <div class="back">
        <div class="backfff">
          <div class="bookimg">
            <img :src="ugcBook.coverName" alt="">
          </div>
          <div class="userimg">
            <img :src="ugcBook.authorNamepath" alt="">
          </div>
          <div class="text">
            邀请你加入“{{ugcBook.ugcBookName}}”进行共同创作
          </div>
        </div>
        <div class="middleimg">
          <img src="../../assets/image/middle_05.png" alt="">
        </div>

        <div class="backfffbottom">
          <div class="addbtn" @click="addbtn">
            加入共创
          </div>
        </div>
      </div>
    </div>
    <invitation v-show="invitation_show"></invitation>
  </div>
</template>
<script>
import headerTitle from '../../components/header/header-title.vue'
import invitation from '../../components/share/invitation_share.vue'
export default {
  components: {
    headerTitle,
    invitation
  },
  data () {
    return {
      titleCon: '共创邀请',
      ugcBook: {
        coverName: ''
      },
      btnshow: false,
      invitation_show: true,
      isLogin: false
    }
  },
  computed: {},
  created () {
    this.islogin()
  },
  mounted () {},
  methods: {
    // 是否登录
    islogin () {
      this.axios({
        method: 'get',
        url: '/v1/bookCreatorInvitation',
        params: {
          ugcBookId: this.$route.query.bookId
        }
      }).then((res) => {
        // console.log(res)
        if (res.data.head.flag === 0) {
          this.ugcBook = res.data.content.ugcBook
          if (res.data.content.isLogin === 1) {
            // console.log(res)
            this.isLogin = true
            this.invitation_show = true
            if (res.data.content.userId === parseInt(this.$route.query.sUserId)) { // 类型不同无法比较
              this.invitation_show = true
            } else {
              this.invitation_show = false
            }
          } else {
            this.invitation_show = false
          // this.$toast(res.data.head.desc)
          }
        }
      })
    },
    // 返回按钮
    titleLeftListener () {
      this.$router.go(-1)
    },
    // 加入共创
    addbtn () {
      if (!this.isLogin) {
        window.open('login?params={"method":"addBookCreator","ugcBookId":' + this.$route.query.bookId + '}')
      } else {
        this.axios({
          method: 'get',
          url: '/v1/addBookCreator',
          params: {
            ugcBookId: this.$route.query.bookId
          }
        }).then((res) => {
          this.$toast(res.data.head.desc)
          setTimeout(function () { window.open('book') }, 1000)
        })
      }
    }
  }
}
</script>
