<style scoped>
  .share_box {
    /* height: 0px; */
    border-radius: 20px 20px 0 0;
    padding: 28px 30px 21px;
    background-color: #f7f7f9;
    position: fixed;
    /* bottom: -400px; */
    bottom: 0px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 5;
  }

  .share_box>ul {
    overflow: hidden;
  }

  .share_box>ul>li {
    float: left;
    width: 25%;
  }

  .share_box>ul>li>img {
    width: 53px;
    height: 53px;
    border-radius: 50%;
  }

  .share_box>ul>li>p {
    margin-top: 7px;
    font-size: 14px;
    font-weight: 400;
    color: #868c97;
    margin-bottom: 27px;
  }

  .btn_false {
    color: #676971;
    padding-top: 13px;
    border-top: 1px solid #f1f1f2;
    margin-bottom: 21px;
  }
  .popTipBox {
      width: 100%;
      height: 110%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      background: rgba(4,5,5,0.8);
  }
  .popTipBox img {
      position: absolute;
      top: 20px;
      right: 30px;
  }
</style>
<template>
  <div class="share_box">
    <ul>
      <li @click="qq">
        <img src="../../assets/image/qq@2x.png" alt="">
        <p>QQ</p>
      </li>
      <li @click="qqkj">
        <img src="../../assets/image/kongjian@2x.png" alt="">
        <p>QQ空间</p>
      </li>
      <li @click="shareFriend">
        <img src="../../assets/image/wx@2x.png" alt="">
        <p>微信</p>
      </li>
      <li @click="pyq">
        <img src="../../assets/image/pengyouquan@2x.png" alt="">
        <p>朋友圈</p>
      </li>
    </ul>
    <!-- <div class="btn_false" @click="btn_false">
      取消
    </div> -->
    <div class="popTipBox" v-show="ismenu" @click="hide">
        <img src="../../assets/image/imgTip.png" width="70%">
    </div>
  </div>
</template>
<script>
// import sdk from '../../commonJS/sdk' // 引入sdk.js
// import wx from 'weixin-js-sdk'
// import sdk from '@/store/sdk'
export default {
  props: ['invitation_show '],
  data () {
    return {
      ismenu: false
    }
  },
  created () {
    this.axios({
      method: 'get',
      url: '/v1/getUgcUrl',
      params: {}
    }).then((res) => {
      // console.log(res.data)
      this.dataurl = res.data
      // console.log(this.dataurl)
    })
  },
  methods: {
    shareFriend () {
      this.ismenubox()
    },
    qq () {
      this.ismenubox()
      // window.location.href = 'http://connect.qq.com/widget/shareqq/index.html?url=' + this.dataurl + '/invitedshare&sharesource=qzone&title=你的分享标题&pics=你的分享图片地址&summary=你的分享描述&desc=你的分享简述&bookid=' + this.$route.query.bookId
    },
    qqkj () {
      this.ismenubox()
      // window.location.href = 'https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' + this.dataurl + '/invitedshare&sharesource=qzone&title=你的分享标题&pics=你的分享图片&summary=你的分享描述信息&bookid=' + this.$route.query.bookId
    },
    wx () {
      this.ismenubox()
    },
    pyq () {
      this.ismenubox()
      // console.log(123)
    },

    btn_false () {
      this.$emit('Modify', false)
    },
    ismenubox () {
      this.ismenu = true
    },
    hide () {
      this.ismenu = false
    }
  }
}
</script>
